import $ from 'jquery';

$(document).ready(function () {
  customTabsWidget();
});

function customTabsWidget() {
  document.querySelectorAll('.js-cutom-tabs-widget').forEach((section) => {
    const buttons = section.querySelectorAll('.tab-button');
    const tabs = section.querySelectorAll('.tab-content');

    buttons.forEach((button) => {
      button.addEventListener('click', () => {
        const tabId = button.getAttribute('data-tab');
        const newActiveTab = document.getElementById(tabId);

        tabs.forEach((tab) => {
          tab.classList.remove('active');
        });

        buttons.forEach((btn) => {
          btn.classList.remove('active');
        });

        newActiveTab.classList.add('active');
        button.classList.add('active');
      });
    });
  });
}
