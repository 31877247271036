import $ from 'jquery';

$(document).ready(function () {
  inventoryHeadWidget();
});

function inventoryHeadWidget() {
  $('.slider-for').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    dots: false,
    asNavFor: '.slider-nav',
  });

  $('.slider-nav').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    arrows: false,
    dots: false,
    centerMode: false,
    focusOnSelect: true,
    vertical: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1201,
        settings: {
          slidesToShow: 4,
          vertical: false,
        },
      },
    ],
  });

  if ($('html').hasClass('no-touchevents')) {
    $('.zoom-container').on('mousemove', function (e) {
      const zoomImage = $(this).find('.zoom-image');
      const offsetX = e.offsetX;
      const offsetY = e.offsetY;
      const width = $(this).width();
      const height = $(this).height();
      const moveX = (offsetX / width) * 100;
      const moveY = (offsetY / height) * 100;

      zoomImage.css('transform-origin', `${moveX}% ${moveY}%`);
    });
  }
}
