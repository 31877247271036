const insertButton = () => {
  const mobileMenuWidget = document.querySelector('.js-mobile-menu-widget');
  const menuId = mobileMenuWidget.getAttribute('data-menu');
  const mainMenu = document.getElementById(`menu-${menuId}`);
  const buttonMarkup =
    '<span class="submenu-trigger"><svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path d="M8.74765 9.15827C8.34983 9.60615 7.65017 9.60615 7.25235 9.15827L0.817841 1.91409C0.244948 1.26911 0.702821 0.25 1.5655 0.25L14.4345 0.250002C15.2972 0.250002 15.7551 1.26911 15.1822 1.91409L8.74765 9.15827Z" fill="#fff"/>\n' +
    '</svg></span>';
  const listItems = mainMenu.querySelectorAll(
    '.menu-item.menu-item-has-children'
  );
  listItems.forEach((listItem) => {
    if (
      listItem.classList.contains('has-submenu-trigger') ||
      listItem.querySelector('button')
    )
      return;
    listItem.insertAdjacentHTML('afterbegin', buttonMarkup);
    listItem.classList.add('has-submenu-trigger');
  });
};

const openAccordion = (accordion, parent) => {
  const toggler = accordion.querySelector('.submenu-trigger');
  const content = accordion.querySelector('ul.sub-menu');
  const contentHeight = parseInt(content.scrollHeight + 16);
  toggler.classList.add('is-active');
  content.classList.add('is-active');
  content.style.maxHeight = contentHeight + 'px';
  parent.style.maxHeight =
    parseInt(parent.style.maxHeight) + contentHeight + 'px';
};

const closeAccordion = (accordion) => {
  const toggler = accordion.querySelector('.submenu-trigger');
  const content = accordion.querySelector('ul.sub-menu');
  toggler.classList.remove('is-active');
  content.classList.remove('is-active');
  content.style.maxHeight = 0;
};

const mobileMenuAccordion = () => {
  const mobileMenuWidget = document.querySelector('.js-mobile-menu-widget');
  const menuId = mobileMenuWidget.getAttribute('data-menu');
  const mainMenu = document.getElementById(`menu-${menuId}`);
  const accordionItems = mainMenu.querySelectorAll(
    'li.menu-item-has-children.has-submenu-trigger'
  );
  accordionItems.forEach((accordion) => {
    const toggler = accordion.querySelector('.submenu-trigger');
    console.log(toggler);
    const content = accordion.querySelector('ul');
    const parent = accordion.parentElement;
    const closestItems = accordion
      .closest('ul')
      .querySelectorAll('li.menu-item-has-children.has-submenu-trigger');

    toggler.onclick = () => {
      if (content.classList.contains('is-active')) {
        closeAccordion(accordion);
      } else {
        closestItems.forEach((accordion) => closeAccordion(accordion));
        openAccordion(accordion, parent);
      }
    };
  });
};

window.onload = function () {
  insertButton();
  mobileMenuAccordion();
};

jQuery(window).on('resize', function () {
  $('.hamburger--elastic').removeClass('is-active');
  $('body').removeClass('lock');
  $('.js-mobile-menu-widget').fadeOut();
  $('.mobile-menu-button').fadeOut();
});
